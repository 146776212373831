
@use "client/css/globals" as *;

.title {
    padding-bottom: 5px;
}

.sync-section > * {
    padding: 15px 30px;
}

.setting-section {
    padding-top: 30px;
}

::v-deep .pilot-card-header {
    display: flex;
    padding: 20px 30px 10px;
}

h3 {
    margin: 0;
}

.status-tag {
    font-weight: normal;
    margin-left: 10px;
}

.title-section {
    margin: 30px 0;
}

.button-with-disabled-reason {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    ::v-deep .button {
        margin-right: 0;
    }
}

.helper-text {
    margin-top: 10px;
}
